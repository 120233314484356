import space from '@/assets/images/space.png';
import styles from '@/ui/avatar/avatar.module.css';
import {AvatarConnectionGroup} from '@/ui/avatar/connection-group/avatar-connection-group';
import {
  Box,
  Avatar as MantineAvatar,
  AvatarProps as MantineAvatarProps,
  MantineFontSize,
  Skeleton,
  Text,
  rem,
  useMantineTheme,
} from '@mantine/core';
import {usePrefab} from '@prefab-cloud/prefab-cloud-react';
import BoringAvatar from 'boring-avatars';
import cx from 'classnames';
import Image from 'next/image';
import {forwardRef, useEffect, useMemo, useState} from 'react';
import {ExpertBadge} from '@/components/expert-badge/expert-badge';

export enum AvatarDecoratarBadges {
  ExpertBadge = 'EXPERT_BADGE',
}

interface AvatarProps extends Omit<MantineAvatarProps, 'name'> {
  // nullable names fall back to empty string placeholders
  name?: MantineAvatarProps['name'] | null;
  badge?: React.ReactNode;
  withBorder?: boolean;
  disabled?: boolean;
  enableSSRFallback?: boolean;
  decoratorBadge?: AvatarDecoratarBadges;
  withBackground?: boolean;
  fontSize?: MantineFontSize;
  classNames?: MantineAvatarProps['classNames'];
}

// this is gross, but there isn't a good way to map the explicit skeleton heights otherwise
// these are taken from the --avatar-size vars, which aren't defined outside the avatar
export const skeletonSizeMap = {
  xs: 'calc(1rem*var(--mantine-scale))',
  sm: 'calc(1.625rem*var(--mantine-scale))',
  md: 'calc(2.375rem*var(--mantine-scale))',
  lg: 'calc(3.5rem*var(--mantine-scale))',
  xl: 'calc(5.25rem*var(--mantine-scale))',
};

const fontSizeMap = {
  xs: rem(0),
  sm: rem(10),
  md: rem(14),
  lg: rem(20),
  xl: rem(24),
};

function getInitials(string: string) {
  const names = string.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

export const Avatar = Object.assign(
  forwardRef<HTMLDivElement, AvatarProps>(function InternalAvatar(props, ref) {
    const [mounted, setMounted] = useState(false);

    const {
      name,
      withBorder = true,
      children,
      badge,
      className,
      src,
      enableSSRFallback = false,
      decoratorBadge: badgeType = null,
      withBackground = true,
      fontSize,
      ...rest
    } = props;
    const theme = useMantineTheme();
    const colors = [theme.colors.primary[8], theme.colors.blue[8], theme.colors.violet[8]];
    const initials = getInitials(name || '');
    const size = (rest.size || 'md') as 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    const {isEnabled} = usePrefab();
    const showExpertBadgeFlag = isEnabled('enable-expert-indicator');

    const key = `${name}-${src}`;

    useEffect(() => {
      setMounted(true);
    }, []);

    const shouldRenderSkeleton = useMemo(() => {
      return enableSSRFallback && !mounted;
    }, [enableSSRFallback, mounted]);

    return shouldRenderSkeleton ? (
      <Skeleton height={skeletonSizeMap[size]} circle />
    ) : (
      <Box className={styles.box} data-disabled={rest.disabled}>
        <MantineAvatar
          key={key}
          ref={ref}
          src={src}
          {...rest}
          className={cx(className, {[styles.withBorder]: withBorder})}
        >
          {withBackground && (
            <div style={{width: '100%', height: '100%'}}>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
              >
                <BoringAvatar colors={colors} name={name || ''} square size="100%" />
              </div>
              <Image
                src={space.src}
                alt={name || ''}
                width={200}
                height={200}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  mixBlendMode: 'screen',
                  transformOrigin: '50% 50%',
                }}
              />
            </div>
          )}
          {children ? (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {children}
            </div>
          ) : (
            <Text
              style={{
                color: 'white',
                fontWeight: 'var(--mantine-font-weight-semi-bold)',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: fontSize ?? fontSizeMap[size],
                // prevents small avatars from breaking initials onto two lines
                whiteSpace: 'nowrap',
              }}
            >
              {initials}
            </Text>
          )}
        </MantineAvatar>
        {showExpertBadgeFlag && badgeType === AvatarDecoratarBadges.ExpertBadge ? (
          <ExpertBadge
            data-size={size}
            style={{
              minWidth: skeletonSizeMap['sm'],
              maxWidth: size === 'xl' ? skeletonSizeMap['lg'] : skeletonSizeMap['md'],
              marginTop: size === 'xl' ? '-14px' : '-10px',
            }}
          />
        ) : null}
        {/* This badge implementation is an over-simplication to handle the Paths Panel case. Would want to revisit this if we want to add full badging support to avatars to make sure we handle all of the cases regarding layout and sizing. */}
        {badge && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              bottom: -10,
            }}
          >
            {badge}
          </div>
        )}
      </Box>
    );
  }),
  {ConnectionGroup: AvatarConnectionGroup},
);
